import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import homeComponent from '../components/home.vue';
import adminComponent from '../components/admin.vue';
import remoteComponent from '../components/remote.vue';
import VueSocketIO from 'vue-socket.io'

Vue.use(VueRouter);
Vue.use(new VueSocketIO({
  debug: false,
  connection: 'wss://ihvh.dequizmasters.nl',
  // options: { path: "" } //Optional options
  // transports: ['websocket'],
}));

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
    { path: '/', component: remoteComponent },
    { path: '/admin', component: adminComponent },
    { path: '/game', component: homeComponent },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
