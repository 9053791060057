<template>
    <div class="game" :style="{ backgroundImage: `url('${map}')` }">
        <div ref="timer" class="timer" :style="{ backgroundImage: `url('${timerImage}')` }">{{ timer }}</div>
        <div 
            class='kaas' 
            ref="kaas" 
            style="transform: rotate(0deg)"
            :style="{ backgroundImage: `url('${kaasImage}')`, top: `${cheeseTopPos}%`, left: `${cheeseLeftPos}%`}" 
        />
        <div v-show="showAnswer" class='answer' ref="answer" style="top: 0%; left: 0%" />
    </div>
</template>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

  .game {
    position: relative;
    width: 100%;
    height: 100%;
    background: {
      size: contain;
      position: center center;
      repeat: no-repeat;
    }
  }
  .kaas {
    display: block;
    position: absolute;
    border-radius: 50%;
    transition-duration: .5s;
    transition-timing-function: linear;
    background: {
      size: contain;
      position: center center;
      repeat: no-repeat;
    }
  }

  .answer {
    display: block;
    position: absolute;
    border-radius: 50%;
    background: rgba(200, 0, 0, 0.75);
    transform: translate(-50%, -50%);
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
  }

  .timer {
    height: 18%;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-family: 'Great Vibes', cursive;
    font-size: 200%;
    color: #263E93;
    box-sizing: border-box;
  }
</style>

<script>

import _ from 'lodash';

export default {
  name: 'gameComponent',

  data() {
    return {
      map            : require("../assets/images/nederland.png"),
      kaasImage      : require("../assets/images/kaas.png"),
      bgImage        : require("../assets/images/background_blur.jpg"),
      timerImage     : require("../assets/images/tegeltje.png"),
      showAnswer     : false,
      timer          : 0,
      rotation       : 0,
      timerHasStarted: false,
      position       : { top: 0, left: 0 },
      testMode       : false,
      settings       : {
        cheeseSize: 3,
        answerSize: 4
      }
    };
  },

  computed: {
    cheeseTopPos() {
      return this.position.top - (this.settings.cheeseSize / 2)
    },
    cheeseLeftPos() {
      return this.position.left - (this.settings.cheeseSize / 2)
    }
  },

  created: function() {

    this.sockets.subscribe('navigate_cheese', (data) => {
      this.navigate(data);
    });
    this.sockets.subscribe('show_answer', (data) => {
        this.setAnswerLocation(data);
    });
    this.sockets.subscribe('hide_answer', (data) => {
        this.hideAnswer();
    });
    this.sockets.subscribe('start', (data) => {
      this.startGame(data);
    });
    this.sockets.subscribe('reset', (data) => {
      this.reset(data);
    });
    this.sockets.subscribe('settings', (data) => {
        this.setSettings(data);
    });
    this.sockets.subscribe('set_test_mode', (data) => {
        console.log("Set test mode", data);
        this.testMode = data;
    });
  },

  mounted() {
    this.$socket.emit('get_settings');
    this.setTimerSize();
    window.addEventListener("resize", _.debounce(this.setTimerSize, 100));
  },

  sockets: {
      connect: function () {
          console.log('socket connected')
      },
  },

  methods: {
    navigate(data) {
      console.log(data, this.testMode);
      if(this.timer > 0 || this.testMode) {
        const kaas           = this.$refs.kaas;
        this.position.top    = data.top;
        this.position.left   = data.left;
        this.rotation        += 4;
        kaas.style.transform = "rotate(" + this.rotation + "deg)";
      }
    },

    reset(data) {
      this.position.top  = data.top;
      this.position.left = data.left;
      this.timer         = 0;
    },

    setAnswerLocation(location) {
      this.showAnswer              = true;
      this.$refs.answer.style.top  = location.top + "%";
      this.$refs.answer.style.left = location.left + "%";
    },

    hideAnswer() {
        this.showAnswer = false;
    },

    startGame(data) {
      this.showAnswer = false;
      if(! this.timerHasStarted) {
        this.$socket.emit('set_test_mode', false);
        this.testMode = false;
        this.timer    = data.time;
        this.startTimer();
      }
    },

    startTimer() {
      this.timerHasStarted = true;

      const interval = setInterval(() => {
        this.timer--;

        if(this.timer <= 0) {
          clearInterval(interval);
          this.timerHasStarted = false;

        }
      }, 1000)
    },

    setSettings(data) {
      const kaas          = this.$refs.kaas;
      const answer        = this.$refs.answer;
      kaas.style.width    = data.cheeseSize + "%";
      kaas.style.height   = data.cheeseSize + "%";
      answer.style.width  = data.answerSize + "%";
      this.position.top   = data.pos.top;
      this.position.left  = data.pos.left;
      this.timer          = data.timer;
      this.testMode       = data.testMode;

      this.settings.cheeseSize = data.cheeseSize;
      this.settings.answerSize = data.answerSize;

      if(this.timer > 0) {
        this.startTimer();
      }
    },

    setTimerSize() {
      const timer = this.$refs.timer;
      const height = timer.offsetHeight;
      console.log(height);
      timer.style.width = height + "px";
      timer.style.lineHeight = height + "px";
      timer.style.fontSize = height / 2.5 + "px";
    }
  }
}
</script>
