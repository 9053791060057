<template>
    <label class="switch">
        <input
            :checked="value"
            type="checkbox"
            @input="$emit('input', $event.target.checked)"
        >
        <span class="slider"></span>
    </label>
</template>

<script>
export default {
    name: "ToggleSwitch",

    props: {
        value: { type: Boolean, default: false }
    }
}
</script>

<style>
:root {
    --toggle-width: 30px;
    --toggle-height: 20px;
    --toggle-padding: 4px;
}
</style>

<style lang="scss" scoped>

.switch {
    position: relative;
    display: inline-block;
    width: var(--toggle-width);
    height: var(--toggle-height);

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: var(--toggle-height);

    &:before {
        position: absolute;
        content: "";
        height: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
        width: calc(var(--toggle-height) - (var(--toggle-padding) * 2));
        left: var(--toggle-padding);
        bottom: var(--toggle-padding);
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    left: calc(var(--toggle-width) - (var(--toggle-padding) * 4));
    //transform: translateX(var(--slider-position-on));
}
</style>