<template>
  <div class="background" :style="{ backgroundImage: `url('${bgImage}')` }">
  <div class="wrapper">
      <game-component />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
    top: 50px;
    width: calc(100vh - 100px);
    height: calc(100vh - 100px);
    margin: 0 auto;
    position: relative;
  }

  .background {
    min-height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
</style>

<script>

import gameComponent from "./game";

export default {
  name: 'homeComponent',
  components: { gameComponent },

  data() {
    return {
      bgImage: require("../assets/images/background_blur.jpg"),
    };
  },
}
</script>
